<style>
#cancellation-report .btn:disabled, #cancellation-report .btn.disabled{
    opacity: 1!important;
    background: #f1a058!important;
}
</style>

<template>
  <div id="cancellation-report" style="padding: 0 1.5rem">
    <div class="pb-1 mb-0 mb-lg-1">
      <SearchForm @search="search" :reportName="reportName" :isProcessing="isProcessing"></SearchForm>
      <ReportTable
        :cancelledOpps="cancellations"
        :totalRows="totalRows"
        :reportName="reportName"
        :isProcessing="isProcessing"
        @search="search"
        @get-details="getDetails"
      ></ReportTable>
    </div>

    <div
      id="detail_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="detail_modal"
      class="modal fade"
      aria-modal="true"
      style="display: none" 
      data-bs-backdrop="static" data-bs-keyboard="false"
    >
      <div role="document" class="modal-dialog modal-lg modal2detail4pk" >
        <div class="modal-content">
          <div class="modal-header">
            <h4 id="detail_modal_label" class="modal-title">
              {{$t("detailHeader")}}
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <dl class="row mt-2 py-2 bg-white rounded shadow px-2">
                <dt class="col-sm-12"><h6>{{$t("custInfo")}}</h6></dt>
                <dd></dd>
                <div class="col-7">
                  <div class="row">
                    <dt class="col-sm-6">{{$t("custName")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.cust_name)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("custMobile")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.cust_mobile)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("custEmail")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.cust_email)}}</dd>
                  </div>
                </div>
                <div class="col-5"></div>
              </dl>
              <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="['Car', 'CarEV', 'MotorBike'].indexOf(opportunity?.product_type) > -1">
                <div class="col-12 col-md-7">
                  <div class="row">
                    <dt class="col-sm-6">{{$t("oppNumber")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.oppname}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("productOption")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.product_option)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("excess")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.excess__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("garage")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.workshop_type__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("driver")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.driver_plan__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("compulsory")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.compulsory_plan__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("startDate")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.start_date)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("stage")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.stagename}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("cancelledDate")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.cancelleddate}}</dd>
                  </div>
                  <div class="row" v-if="opportunity?.vehicleinspection === 'Open'">
                    <dt class="col-sm-6">{{$t("vehicleInspection.label")}}</dt>
                    <dd class="col-sm-6" style="color: #ff5011">{{$t("vehicleInspection.required")}}</dd>
                  </div>
                  <div class="row" v-if="Global.authority === 'ADMIN'">
                    <dt class="col-sm-6">{{$t("brokerName")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.broker_name}}</dd>
                  </div>
                  <div class="row" v-if="Global.authority !== 'AGENT_USER'">
                    <dt class="col-sm-6">{{$t("agentEmail")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.intermediary_rept_email}}</dd>
                  </div>
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <dt class="col-sm-6">{{$t("product")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(displaySpecifiedProductType(opportunity?.product_type))}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("brand")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.make__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("model")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.model_family__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("subModel")}}</dt>
                    <dd class="col-sm-6">-</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("year")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.year_of_manufacture__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("carPlate")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.car_plate_number__c)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("carPlateProvince")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.car_plate_province__c)}}</dd>
                  </div>
                  <div class="row">
                    <!-- <dt class="col-sm-6">{{$t("netPremiumIncludeVatAndStamp")}}</dt> -->
                    <dt class="col-sm-6">{{$t("netPremiumPaid")}}</dt>
                    <dd class="col-sm-6">
                      {{!isNullOrEmpty(opportunity?.premium) ? formattPrice(opportunity?.premium,2) : showNullWithDash(opportunity?.premium)}}
                    </dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("paymentPlan")}}</dt>
                    <dd class="col-sm-6">{{!isNullOrEmpty(opportunity?.payment_frequency) ? displayPaymentPlan(opportunity?.payment_frequency) : showNullWithDash(opportunity?.payment_frequency)}}</dd>
                  </div>
                </div>
              </dl>
              <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-else>
                <div class="col-7">
                  <div class="row">
                    <dt class="col-sm-6">{{$t("oppNumber")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.oppname}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("productOption")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.product_option)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("startDate")}}</dt>
                    <dd class="col-sm-6">{{showNullWithDash(opportunity?.start_date)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("stage")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.stagename}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("cancelledDate")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.cancelleddate}}</dd>
                  </div>
                  <div class="row" v-if="Global.authority === 'ADMIN'">
                    <dt class="col-sm-6">{{$t("brokerName")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.broker_name}}</dd>
                  </div>
                  <div class="row" v-if="Global.authority !== 'AGENT_USER'">
                    <dt class="col-sm-6">{{$t("agentEmail")}}</dt>
                    <dd class="col-sm-6">{{opportunity?.intermediary_rept_email}}</dd>
                  </div>
                </div>
                <div class="col-5">
                  <div class="row">
                    <dt class="col-sm-6">{{$t("product")}}</dt>
                    <dd class="col-sm-6">{{displaySpecifiedProductType(opportunity?.product_type)}}</dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("businessValidate")}}</dt>
                    <dd class="col-sm-6" v-bind:class="{'text-danger' : showBusinessValidate(opportunity?.approval_status__c) == 'notPass'}">{{!isNullOrEmpty(opportunity?.approval_status__c) ? $t(showBusinessValidate(opportunity?.approval_status__c)) : showNullWithDash(opportunity?.approval_status__c)}}</dd>
                  </div>
                  <div class="row">
                    <!-- <dt class="col-sm-6">{{$t("netPremiumIncludeVatAndStamp")}}</dt> -->
                    <dt class="col-sm-6">{{$t("netPremiumPaid")}}</dt>
                    <dd class="col-sm-6">
                      {{!isNullOrEmpty(opportunity?.premium) ? formattPrice(opportunity?.premium,2) : showNullWithDash(opportunity?.premium)}}
                    </dd>
                  </div>
                  <div class="row">
                    <dt class="col-sm-6">{{$t("paymentPlan")}}</dt>
                    <dd class="col-sm-6">{{!isNullOrEmpty(opportunity?.payment_frequency) ? displayPaymentPlan(opportunity?.payment_frequency) : showNullWithDash(opportunity?.payment_frequency)}}</dd>
                  </div>
                </div>
              </dl>
              <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="!isNullOrEmpty(opportunity?.premiumDetails)">
                <dt class="col-sm-12"><h6>{{$t("premiumInformation")}}</h6></dt>
                <dd></dd>
                <div class="col-12 table-responsive">
                  <table class="table table-flush dataTable-table z1 table-hover">
                    <thead class="text-primary">
                      <tr>
                        <th scope="col">{{$t('transactionDate')}}</th>
                        <th scope="col">{{$t('transactionType')}}</th>
                        <!-- <th scope="col">{{$t('grossPremium')}}</th> -->
                        <th scope="col">{{$t('grossPremiumTransacted')}}</th>
                        <!-- <th scope="col">{{$t('netPremiumIncludeVatAndStamp')}}</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(premiumDetail, index) in opportunity.premiumDetails" :key="'premiumDetail' + index">
                        <td>{{premiumDetail.quote_transacted_date}}</td>
                        <td>
                          {{premiumDetail.transaction_type__c}}
                          <p class="mb-0" v-if="premiumDetail.transaction_type__c === 'Endorsement'"><small>{{premiumDetail.endorsement_description__c}}</small></p>
                          <p class="mb-0" v-if="premiumDetail.transaction_type__c === 'Cancellation'"><small>{{premiumDetail.reason_for_cancellation__c}}</small></p>
                        </td>
                        <td>{{formattPrice(premiumDetail.trans_net_premium__c,2)}}</td>
                        <!-- <td>{{formattPrice(premiumDetail.policy_gross_premium__c,2)}}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dl>

              <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="opportunity?.policyDocuments">
                <dt class="col-sm-12"><h6>{{$t("downloadPolicyDocument")}}</h6></dt>
                <dd></dd>
                <div class="col-12">
                  <DownloadDocument v-if="opportunity.policyDocuments" :policyDocuments="opportunity.policyDocuments"></DownloadDocument>            
                </div> 
              </dl>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary text-white bg-gradient-primary"
              data-bs-dismiss="modal"
            >
            {{$t('menu.close')}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="teleport-target"></div>
  </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import SearchForm from "@/components/SearchForm";
import ReportTable from "@/components/ReportTable";
import DownloadDocument from "@/components/DownloadDocument";
import axios from "axios";

export default {
  mixins: [mixin],
  name: "CancellationReport",
  components: { SearchForm, ReportTable, DownloadDocument },
  data: function () {
    return {
      Global,
      cancellations: [],
      totalRows: 0,
      opportunity: null,
      isProcessing: false,
      reportName: "cancellationReport"
    };
  },
  created() {
    if(!this.isNullOrEmpty(this.$route.query.start) && !this.isNullOrEmpty(this.$route.query.end) && !this.isNullOrEmpty(this.$route.query.dateType)){
      Global.searchConditions[this.reportName].dateFrom = this.$route.query.start;
      Global.searchConditions[this.reportName].dateTo = this.$route.query.end;
      Global.searchConditions[this.reportName].policyStatus = this.$route.query.stage ? this.$route.query.stage : "";
      Global.searchConditions[this.reportName].status = this.$route.query.status ? this.$route.query.status : "";
      Global.searchConditions[this.reportName].dateType = this.$route.query.dateType;
      Global.searchConditions[this.reportName].productType =  "";
    }else{
      Global.searchConditions[this.reportName].dateFrom = "";
      Global.searchConditions[this.reportName].dateTo = "";
      Global.searchConditions[this.reportName].productType = "";
      Global.searchConditions[this.reportName].policyStatus = "Closed Won";
      Global.searchConditions[this.reportName].dateType = "cancelleddate";
      Global.searchConditions[this.reportName].sortKey = "cancelleddate";
      Global.searchConditions[this.reportName].sortDirection = "desc";
      Global.searchConditions[this.reportName].oppNumber = "";
      Global.searchConditions[this.reportName].custName = "";
      Global.searchConditions[this.reportName].phoneNumber = "";
      Global.searchConditions[this.reportName].custEmail = "";
      Global.searchConditions[this.reportName].carPlate = "";
      Global.searchConditions[this.reportName].agentEmail = "";
      Global.searchConditions[this.reportName].currentPage = 1;
      Global.searchConditions[this.reportName].rowsPerPage = 20;
      Global.searchConditions[this.reportName].isAdvanceSearch = false;
    }
    this.eventTracking('mainMenu', 'Menu_OppReport', 'Portal');
  },
  mounted() {
    var vm = this;
    this.hide2menu();
    this.search();
    var myModalEl = document.getElementById('detail_modal')
    if(myModalEl){
      myModalEl.addEventListener('hide.bs.modal', function () {
        vm.opportunity = null;
      })
    }
  },
  methods: {
    openPreviewModal: function (img) {
            var vm = this;
            vm.showItem = img;
            var modal = this.modal("#previewModal");
            if( modal ){
                modal.show();
            }
        }  ,
    async search() {
      this.isProcessing = true;
      try {
        var response = await axios.get(
          // Global.apiUrl + "/opportunities",
          Global.apiUrl + "/cancellationReport",
          {
            params: Global.searchConditions[this.reportName],
          }
        );
        this.cancellations = response.data.cancellations;
        this.totalRows = response.data.count;
        this.isProcessing = false;
      } catch (error) {
        if (error?.response?.status === 401) {
          this.isProcessing = false;
          this.$router.go();
        }
        console.error(error);
      }
    },
    async getDetails(opportunity){
      this.topLoading("show");
      this.opportunity = Object.assign({}, opportunity);
      try {
        var response = await axios.get(Global.apiUrl + "/cancellationDetail/" + opportunity.oppname + "/" + opportunity.product_type);
        if(Object.keys(response.data).length > 0){
          var details = response.data.detail;
          this.opportunity.premiumDetails = response.data.premiumDetail;
          this.opportunity.policyDocuments = response.data.policyDocuments.filter(i => i.quoteName == this.opportunity.active_quote_name);
          this.opportunity.quoteid = details.quoteid;
           if(['Car', 'CarEV', 'MotorBike'].indexOf(this.opportunity.product_type) > -1){
            this.opportunity.excess__c = details.excess__c;
            this.opportunity.workshop_type__c = details.workshop_type__c;
            this.opportunity.driver_plan__c = details.driver_plan__c;
            this.opportunity.compulsory_plan__c = details.compulsory_plan__c;
            this.opportunity.make__c = details.make__c;
            this.opportunity.model_family__c = details.model_family__c;
            this.opportunity.year_of_manufacture__c = details.year_of_manufacture__c;
            this.opportunity.car_plate_number__c = details.car_plate_number__c;
            this.opportunity.car_plate_province__c = details.car_plate_province__c;
            this.opportunity.vehicleinspection = details.vehicleinspection;
            this.opportunity.oppid = details.oppid;
            this.opportunity.vid_sfid = details.vid_sfid;
            this.opportunity.plan_type = details.plan_type;
          }else{
            this.opportunity.approval_status__c = details.approval_status__c;
          }  
        }
        this.topLoading("hide");
        var modal = this.modal("#detail_modal");
        if( modal ){
          modal.show();
        }
      }catch (error) {
        if (error?.response?.status === 401) {
          this.topLoading("hide");
          this.$router.go();
        }
        console.error(error);
      }
    }
  },
  computed: {
    isShowPayOnlineLink: function(){
      return this.opportunity?.transaction_type__c === 'New Biz' 
              && this.opportunity?.online_stage === 'Proceed to payment' 
              && this.opportunity?.stagename === 'Proposal'
              && this.opportunity?.product_type != 'Extended Warranty';
    },
    isShowRecurringLink: function(){
      return ['Ground agent', 'Traditional broker'].includes(Global.brokerDetails.accountSource) 
              && this.opportunity?.stagename === 'Closed Won' 
              && this.opportunity?.paymentURLRecurring;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "detailHeader": "Detail",
    "oppNumber": "Opportunity Number",
    "productOption": "Product Option",
    "excess": "Excess",
    "garage": "Garage",
    "driver": "Driver",
    "compulsory": "Compulsory",
    "createdDate": "Created Date",
    "startDate": "Start date",
    "activateDate":	"Activated Date",
    "closedWonDate": "Closed Won Date",
    "stage": "Stage",
    "status": "Status",
    "brokerName": "Broker Name",
    "vehicleInspection": {
      "label": "Vehicle Inspection",
      "required": "Required",
      "notRequired": "Not Required"
    },
    "agentEmail": "Agent email",
    "product": "Product",
    "brand": "Brand",
    "model": "Model",
    "subModel": "Sub Model",
    "year": "Years of manufacture",
    "carPlate": "Car Plate",
    "carPlateProvince":"Province",
    "custInfo": "Customer Information",
    "custName": "Name",
    "custMobile": "Mobile Number",
    "custEmail": "Email",
    "link": "Link",
    "retrieveQuoteLink": "Retrieve Quote Link",
    "click": "Click",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "Business validate",
    "notPass": "Not Pass",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "premiumInformation": "Premium Information",
    "transactionDate": "Transaction date",
    "transactionType": "Transaction type",
    "paymentPlan": "Payment Plan",
    "netPremiumIncludeVatAndStamp": "Net Premium (Including VAT & Stamp)",
    "grossPremium": "Gross Premium",
    "grossPremiumTransacted": "Gross Premium Transacted",
    "uploadDocument": "Upload document",
    "downloadPolicyDocument": "Download Documents",
    "copyLink": "Copy link",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "netPremiumPaid": "Net Premium Paid",
    "cancelledDate": "Cancelled Date"
  },
  "th": {
    "detailHeader": "รายละเอียด",
    "oppNumber": "หมายเลขใบเสนอราคา",
    "productOption": "ตัวเลือกผลิตภัณฑ์",
    "excess": "ค่าเสียหายส่วนแรก",
    "garage": "อู่",
    "driver": "ผู้ขับขี่",
    "compulsory": "พ.ร.บ",
    "createdDate": "วันที่สร้าง",
    "startDate": "วันเริ่มกรมธรรม์",
    "activateDate":	"วันที่ปิดการขาย",
    "closedWonDate": "วันที่ปิดการขาย",
    "stage": "สถานะใบเสนอ",
    "status": "สถานะกรมธรรม์",
    "brokerName": "โบรกเกอร์",
    "vehicleInspection": {
      "label": "ตรวจสภาพรถ",
      "required": "ต้องตรวจสภาพรถ",
      "notRequired": "ไม่ต้องตรวจสภาพรถ"
    },
    "agentEmail": "อีเมล์ที่ปรึกษา",
    "product": "ผลิตภัณฑ์",
    "brand": "ยี่ห้อ",
    "model": "รุ่น",
    "subModel": "รายละเอียดรุ่น",
    "year": "ปีที่ผลิต",
    "carPlate": "ป้ายทะเบียน",
    "carPlateProvince":"ป้ายทะเบียนรถ จังหวัด",
    "custInfo": "รายละเอียดข้อมูลลูกค้า",
    "custName": "ชื่อลูกค้า",
    "custMobile": "เบอร์โทรศัพท์",
    "custEmail": "อีเมล์",
    "link": "ลิงก์",
    "retrieveQuoteLink": "ดูใบเสนอราคา",
    "click": "คลิก",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "การรับประกัน",
    "notPass": "ไม่ผ่าน",
    "fullPayment": "จ่ายเต็มจำนวน",
    "12monthsInstallment": "ผ่อน 12 งวด",
    "10monthsInstallment": "ผ่อน 10 งวด (มีมัดจำ)",
    "10monthsInstallmentNoDeposit": "ผ่อน 10 งวด",
    "4monthsInstallment": "ผ่อน 4 งวด",
    "premiumInformation": "ข้อมูลเบี้ยประกัน",
    "transactionDate": "วันที่ทำรายการ",
    "transactionType": "ประเภทการทำรายการ",
    "paymentPlan": "แผนการชำระเงิน",
    "netPremiumIncludeVatAndStamp": "เบี้ยประกันภัยสุทธิ (รวมภาษีมูลค่าเพิ่มและตราประทับ)",
    "grossPremium": "เบี้ยประกันภัยรวม",
    "grossPremiumTransacted": "Gross Premium Transacted",
    "uploadDocument": "อัพโหลดเอกสาร",
    "downloadPolicyDocument": "ดาวน์โหลดเอกสาร",
    "copyLink": "คัดลอกลิงค์",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "netPremiumPaid": "Net Premium Paid",
    "cancelledDate": "วันที่ยกเลิกกรมธรรม์"
  },
  "zh": {
    "detailHeader": "机会详情",
    "oppNumber": "机会号码",
    "productOption": "Product Option",
    "excess": "超额部分",
    "garage": "维修店铺",
    "driver": "司机",
    "compulsory": "强制性的",
    "createdDate": "创建日期",
    "startDate": "开始日期",
    "activateDate":	"Activated Date",
    "closedWonDate": "Closed Won Date",
    "stage": "Stage",
    "status": "状态",
    "brokerName": "经纪人姓名",
    "vehicleInspection": {
      "label": "车辆检验",
      "required": "需要",
      "notRequired": "不需要"
    },
    "agentEmail": "代理邮箱",
    "product": "产品",
    "brand": "品牌",
    "model": "型号",
    "subModel": "子型号",
    "year": "生产年份",
    "carPlate": "车牌",
    "carPlateProvince":"Province",
    "custInfo": "客户资料",
    "custName": "名称",
    "custMobile": "客户手机号码",
    "custEmail": "客户邮箱",
    "link": "链接",
    "retrieveQuoteLink": "检索报价链接",
    "click": "点击",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "商业验证",
    "notPass": "不通过",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "premiumInformation": "Premium Information",
    "transactionDate": "Transaction date",
    "transactionType": "Transaction type",
    "paymentPlan": "Payment Plan",
    "netPremiumIncludeVatAndStamp": "Net Premium (Including VAT & Stamp)",
    "grossPremium": "Gross Premium",
    "grossPremiumTransacted": "Gross Premium Transacted",
    "uploadDocument": "Upload document",
    "downloadPolicyDocument": "Download Documents",
    "copyLink": "Copy link",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "netPremiumPaid": "Net Premium Paid",
    "cancelledDate": "Cancelled Date"
  }
}
</i18n>